import React from "react"
import { Container, Row, Col, Image, Button } from "react-bootstrap"
import "../components/Jobs.scss"
import backArrow from "../assets/left-arrow.png"
import ReactHtmlParser from "react-html-parser"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import JobStructuredData from "../components/JobStructuredData"
import Logo from "../components/Logo"
import SocialMediaIcons from "../components/SocialMediaIcons"
import CTA from "../components/SocialIconCTA"
import Video from "../components/Video"
import VideoCTA from "../components/VideoCTA"

export default function JobDetailsComponent({ pageContext }) {
  const { job } = pageContext
  const { videoURL } = pageContext
  return (
    <Container>
      <Helmet>
        <title>
          {job == null || job.title == null
            ? "IQ Inc. Job in Pittsburgh, PA"
            : `${job.title} in Pittsburgh PA | IQ Inc.`}
        </title>
        <meta
          name="description"
          content={
            job == null || job.title == null
              ? "IQ Inc. Job in Pittsburgh, PA"
              : job.summary
          }
        ></meta>
        <meta name="author" content="IQ Inc. Careers"></meta>
      </Helmet>
      <JobStructuredData job={job} />
      <Row className="top-row-margin row-margins">
        <Col>
          <Link className="back-link" to="https://iq-inc.com/careers/#jobs">
            <Image src={backArrow} className="back-arrow" />
            <span>Back to job openings</span>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="iqlogo-wrapper">
            <a href="https://iq-inc.com/" title="IQ Inc">
              <Logo />
            </a>
          </div>
        </Col>
      </Row>
      <Row className="row-margins">
        <Col>
          <div className="job-openings-heading center-class">{job.title}</div>
        </Col>
      </Row>
      <Row className="row-margins">
        <Col className="center-class">
          <Button className="apply-now-button" href={job.applyLink}>
            Apply Now
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="gray-line-separator">
          <div className="job-details-gray-line"></div>
        </Col>
      </Row>

      {/* video conditional -- if URl then render otherwise nothing*/}

      {videoURL ? <VideoCTA /> : <div></div>}

      {videoURL ? (
        <Video videoSrcURL={videoURL} videoTitle={job.title} />
      ) : (
        <div></div>
      )}

      <Row className="row-margins">
        <Col>
          {/* Parse HTML string as HTML */}
          <div>{ReactHtmlParser(job.description)}</div>
        </Col>
      </Row>
      <Row className="row-margins">
        <Col className="center-class">
          <Row>
            <SocialMediaIcons />
          </Row>
          <CTA />
        </Col>
      </Row>
    </Container>
  )
}
