import React from "react"
import { Helmet } from "react-helmet"

export default function JobStructuredData(props) {
  let ldJson = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: props.job.title,
    url: `https://careers.iq-inc.com/jobs/${props.job.slug}/`,
    description: props.job.description,
    identifier: {
      "@type": "Organization",
      name: "IQ Inc.",
      value: props.job.adId,
    },
    datePosted: props.job.postedAt.split("T")[0],
    validThrough: props.job.expiresAt.split("T")[0],
    employmentType: "FULL_TIME",
    image: "https://iq-inc.com/wp-content/uploads/2017/04/iq_lg_logo.png",
    hiringOrganization: {
      "@type": "Organization",
      name: "IQ Inc.",
      sameAs: "https://iq-inc.com",
      logo: "https://iq-inc.com/wp-content/uploads/2017/04/iq_lg_logo.png",
    },
    jobLocation: [
      {
        "@type": "Place",
        address: {
          "@type": "PostalAddress",
          streetAddress: "2790 Mosside Blvd #305",
          addressLocality: "Monroeville",
          addressRegion: "PA",
          postalCode: "15146",
          addressCountry: "US",
        },
      },
      {
        "@type": "Place",
        address: {
          "@type": "PostalAddress",
          addressLocality: "Pittsburgh",
          addressRegion: "PA",
          addressCountry: "US",
        },
      },
    ],
  }

  if (props.job.salary) {
    ldJson.baseSalary = {
      "@type": "MonetaryAmount",
      currency: "USD",
      value: {
        "@type": "QuantitativeValue",
        minValue: props.job.salary.rateLow,
        maxValue: props.job.salary.rateHigh,
        unitText: "YEAR",
      },
    }
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
    </Helmet>
  )
}
