import React from "react"

export default function Video({ videoSrcURL, videoTitle, ...props }) {
  return (
    <div className="video-wrapper">
      <iframe
        width="560"
        height="315"
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowfullscreen="true"
      ></iframe>
    </div>
  )
}
